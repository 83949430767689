<!-- Features Start -->
<section class="section bg-backf" id="features">
    <div class="container mt-2">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mt-5 mb-5">
                    <h3 class="font-weight-normal text-dark">Our <span class="text-warning">Features</span></h3>
                    <!-- <p class="text-muted">Maecenas tempus tellus eget condimentum rhoncus sem quam semper libero pulvinar hendrerit id lorem.</p> -->
                </div>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-md-5" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/warranty-management.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
            <div class="col-md-6 offset-md-1 order-last order-sm-0">
                <div class="features-box mt-5 mt-sm-0 mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-laptop-mac"></i>
                    </div>
                    <!-- <h5 class="text-dark font-weight-normal mb-3 pt-3">Warranty/Maintenance Management</h5> -->
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">Warranty And Guarantee Management</h5>
                    <p class="text-muted text-justify mb-3 f-15">Warranty management is an important part of any
                        business. It helps companies to ensure that their products and services are delivered in a
                        timely and cost-effective manner. With the help of warranty management, businesses can easily
                        keep track of their warranties, manage claims, and provide customers with the best possible
                        service,</p>
                    <a class="f-16 text-primary" routerLink="/moreinfo/wgmanagement">Read More <span
                            class="right-icon ml-2">&#8594;</span></a>
                </div>
            </div>
        </div>
        <!-- The use of AI in warranty management has become increasingly popular due to its ability to automate processes such as claims processing, customer service, and warranty analytics. AI-based tools can also be used to identify potential problems with warranties before they arise, allowing companies to take proactive steps towards resolving them quickly and efficiently. -->


        <div class="row align-items-center mt-5">
            <div class="col-md-6 order-last order-sm-0">
                <div class="features-box mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-account-group"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">ERP</h5>
                    <p class="text-muted text-justify mb-3 f-15">Enterprise resource planning (ERP) is a business
                        process management system that enables companies to manage their operations and resources more
                        efficiently. It helps businesses streamline their processes, increase productivity, reduce
                        costs, and improve customer service. ERP software can be used to manage tasks such as</p>
                    <a routerLink="/moreinfo/erp" class="f-16 text-primary">Read More <span
                            class="right-icon ml-2">&#8594;</span></a>
                </div>
            </div>
            <div class="col-md-5 offset-md-1 mt-5 mt-sm-0"
                style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/erp.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>
        <!-- By utilizing ERP systems, companies can gain insights into their operations and make informed decisions that will help them reach their goals. -->

        <div class="row align-items-center mt-5">
            <div class="col-md-5" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/gst.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
            <div class="col-md-6 offset-md-1 order-last order-sm-0">
                <div class="features-box mt-5 mt-sm-0 mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-chart-bell-curve"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">GST</h5>
                    <p class="text-muted text-justify mb-3 f-15">GST is an important part of doing business in India. It
                        is a complex system that requires businesses to keep accurate records of their invoices,
                        billing, and other financial information. With the advent of technology, GST compliance can now
                        be done more efficiently with automated calculations and website integration. This article will
                        discuss </p>
                    <a routerLink="/moreinfo/gst" class="f-16 text-primary">Read More <span
                            class="right-icon ml-2">&#8594;</span></a>
                </div>
            </div>
        </div>
        <!-- We will look at ways to create an efficient GST system for businesses, including automatic calculations and website integration for easier management. Finally, we will discuss how this system can help businesses save time and money in the long run. -->

        <div class="row align-items-center mt-5">
            <div class="col-md-6 order-last order-sm-0">
                <div class="features-box mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-account-group"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">Scan And Pay</h5>
                    <p class="text-muted text-justify mb-3 f-15">In today's digital world, the convenience of paying for
                        goods and services has become much easier. With the introduction of scan and pay apps like
                        Paytm, PhonePe and Gpay, customers can now make payments quickly and securely. Scan and pay apps
                        provide a secure platform for customers to make payments without having to carry cash </p>
                    <a routerLink="/moreinfo/scanpay" class="f-16 text-primary">Read More <span
                            class="right-icon ml-2">&#8594;</span></a>
                </div>
            </div>
            <div class="col-md-5 offset-md-1 mt-5 mt-sm-0"
                style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/scan-and-pay.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>
        <!-- They are becoming increasingly popular in India as more people are opting for this convenient method of payment over traditional methods like cash or cards. This makes it easier for businesses to accept payments from their customers without having to worry about security issues or delays in processing payments. -->

        <div class="row align-items-center mt-5">
            <div class="col-md-5" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/employee-management.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
            <div class="col-md-6 offset-md-1 order-last order-sm-0">
                <div class="features-box mt-5 mt-sm-0 mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-chart-bell-curve"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">Employee Management</h5>
                    <p class="text-muted text-justify mb-3 f-15">Employee management is a key factor in the success of
                        any company. It involves recruiting the right people, setting salaries, providing transport and
                        health benefits, managing layoffs, and creating a positive culture. All these aspects need to be
                        managed carefully and strategically to ensure that the company runs smoothly. The hiring </p>
                    <a routerLink="/moreinfo/empmanagement" class="f-16 text-primary">Read More <span
                            class="right-icon ml-2">&#8594;</span></a>
                </div>
            </div>
        </div>
        <!-- 
        Salaries should also be set fairly to ensure employee satisfaction and retention. Providing transport and health benefits can help improve employee morale and productivity. Layoffs should be handled with sensitivity as it affects both employees' lives and the company's reputation. Finally, creating a positive work culture is essential for any successful organization as it encourages collaboration among team members and boosts creativity. -->

        <div class="row align-items-center mt-5">
            <div class="col-md-6 order-last order-sm-0">
                <div class="features-box mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-account-group"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">Self-Checkout</h5>
                    <p class="text-muted text-justify mb-3 f-15">Self-checkout is a convenient and efficient way to shop
                        from the comfort of your own home. With this technology, customers can quickly and easily
                        purchase items without having to wait in line or interact with a clerk. It also eliminates the
                        need for store employees to manually scan each item. Self-checkout is an end-to-end process that
                    </p>
                    <a routerLink="/moreinfo/selfcheckout" class="f-16 text-primary">Read More <span
                            class="right-icon ml-2">&#8594;</span></a>
                </div>
            </div>
            <div class="col-md-5 offset-md-1 mt-5 mt-sm-0"
                style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/self-checkout.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>
    </div>
    <!-- Customers can scan their items, enter payment information, receive messages about discounts or promotions, and even receive rewards for their purchases. This technology makes it easy for customers to get what they need in a timely manner while also saving them time and money. Self-checkout is becoming increasingly popular as more businesses are looking for ways to reduce labor costs while still providing exceptional customer service. -->
</section>
<!-- Features End -->

<!-- Counter Start -->
<!-- <section class="section counter-bg" style="background: url(assets/images/counter-bg-1.jpg) center center">
    <div class="bg-overlay op-75"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6">
                <div class="text-center mb-5">
                    <h2 class="font-weight-normal text-white mb-2">We've Completed</h2>
                    <h5 class="font-weight-normal text-white-70">More Than 1000+ Work Successfully</h5>
                </div>
            </div>
        </div>
        <div class="row" id="counter">
            <div class="col-lg-3 col-md-6">
                <div class="counter-box text-center text-white mb-4 mb-lg-0">
                    <i class="pe-7s-like2 counter-icon mb-4"></i>
                    <h2 class="mb-2 font-weight-normal"><span class="counter-value mt-4" [CountTo]="620" [from]="0" [duration]="1"></span>+</h2>
                    <p class="mb-0 text-white-70 text-uppercase f-14">Successful Project</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="counter-box text-center text-white mb-4 mb-lg-0">
                    <i class="pe-7s-light counter-icon mb-4"></i>
                    <h2 class="mb-2 font-weight-normal"><span class="counter-value mt-4" [CountTo]="350" [from]="0"
                        [duration]="1"></span>+</h2>
                    <p class="mb-0 text-white-70 text-uppercase f-14">Project Ideas</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="counter-box text-center text-white mb-4 mb-lg-0">
                    <i class="pe-7s-smile counter-icon mb-4"></i>
                    <h2 class="mb-2 font-weight-normal"><span class="counter-value mt-4" [CountTo]="100" [from]="0"
                        [duration]="1"></span>+</h2>
                    <p class="mb-0 text-white-70 text-uppercase f-14">Satisfied Clients</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="counter-box text-center text-white mb-4 mb-lg-0">
                    <i class="pe-7s-star counter-icon mb-4"></i>
                    <h2 class="mb-2 font-weight-normal"><span class="counter-value mt-4" [CountTo]="590" [from]="0"
                        [duration]="1"></span></h2>
                    <p class="mb-0 text-white-70 text-uppercase f-14">Award Win</p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- Counter End -->