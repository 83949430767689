<!-- Contact Us Start -->
<section class="section" id="contact">
    <div class="container mt-5">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mt-3 mb-5">
                    <h3 class="font-weight-normal text-dark">Get In <span class="text-warning"> Touch</span></h3>
                    <p class="text-muted pb-4 pt-2">Whether you are looking for advice, information or just want to get in touch with us – we are here for you. </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4">
                <div class="contact-address">
                    <h4 class="text-dark mb-5">Contact Info</h4>
                    <!-- <p class="text-muted f-15">Sed perspici unade omnis natus error sit voluptatem accusantium doloremque minus cumque.</p> -->
                    <p class="text-muted f-15 mb-4">Welcome to our company! We are here to provide you with the best customer service and help you with any of your needs .We are here to answer any questions or concerns you may have. Thank you for choosing us!</p>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="contact-address">
                            <h5 class="text-dark mb-3 f-16">Address</h5>
                            <p class="text-muted f-15">02, Parkridge 6th floor, Baner-Pashan Link Rd, near Kumar Shantiniketan, Pashan, Pune, Maharashtra 411021</p>
                        </div>
                    </div>
                    <!-- <div class="col-md-6">
                        <div class="contact-address">
                            <h5 class="text-dark mb-3 f-16">Address - B</h5>
                            <p class="text-muted f-15">02, Parkridge 6th floor, Baner-Pashan Link Rd, near Kumar Shantiniketan, Pashan, Pune, Maharashtra 411021</p>
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-7 offset-lg-1">
                <div class="custom-form mt-4 mt-lg-0">
                    <div id="message"></div>
                    <form method="post" action="php/contact.php" name="contact-form" id="contact-form">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group app-label">
                                    <label for="name">Name</label>
                                    <input name="name" id="name" type="text" class="form-control" placeholder="Enter your name..">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group app-label">
                                    <label for="email">Email address</label>
                                    <input name="email" id="email" type="email" class="form-control" placeholder="Enter your email..">
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group app-label">
                                    <label for="subject">Subject</label>
                                    <input type="text" class="form-control" id="subject" placeholder="Enter Subject.." />
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group app-label">
                                    <label for="comments">Message</label>
                                    <textarea name="comments" id="comments" rows="3" class="form-control" placeholder="Enter message.."></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <button type="submit" id="submit" name="send" class="btn btn-warning">Send Message <i class="mdi mdi-telegram ml-2"></i></button>
                                <div id="simple-msg"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Contact Us End -->

<!-- Subscribe Start -->
<!-- <section class="section bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-7">
                <div class="row">
                    <div class="col-4">
                        <div class="subscribe-icon">
                            <img src="assets/images/icon/1.png" alt="" class="img-fluid mx-auto d-block">
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="subscribe-icon">
                            <img src="assets/images/icon/2.png" alt="" class="img-fluid mx-auto d-block">
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="">
                            <img src="assets/images/icon/3.png" alt="" class="img-fluid mx-auto d-block">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-6">
                <div class="text-center mt-5 mb-4">
                    <h6 class="text-muted font-weight-normal">Subscribe To Our Newsletter For New Content, <span class="d-block mt-2">Update And Giveaways!</span></h6>
                </div>
                <div class="text-center subscribe-form mt-4">
                    <form action="#">
                        <input type="text" placeholder="Your Email Address...">
                        <button type="submit" class="btn btn-warning">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- Subscribe End -->