<section class="footer">
    <div class="container">
        <div class="row quote">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <img class="img-fluid tagline" style="width: 100% !important; height: auto !important;"
                    src="../../../assets/images/tagline.png" alt="" />
                <div class="row thought">
                    <div class="col-lg-12">
                        <div class="tagline mb-4 font-weight-normal line-height-1_4">
                            <h4>
                                The technology that looks impossible today will be a part of our
                                life tomorrow. Iceipts Technology creating difference.
                            </h4>
                        </div>
                        <div class="ceo">
                            <h6 class="mt-3 font-weight-normal">
                                <span class="ceoName">SIBANEE KAR</span><br />
                                <span class="ceoPosition">Founder and CEO</span>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>