<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light nav-sticky bg-blue"
  id="navbar">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand logo">
      <img style="height: 1.5rem" src="assets/images/logos/logo-symbol-white.png">
      <img style="height: 1.4rem;margin-left: 10px;" class="logo2" src=" assets/images/logos/logo-white.png">
    </a>
    <button class=" navbar-toggler p-0" type="button" data-toggle="collapse" data-target="#navbarCollapse03"
      aria-controls="navbarCollapse03" aria-expanded="false" aria-label="Toggle navigation"
      (click)="isCollapsed = !isCollapsed">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarCollapse03" [ngbCollapse]="isCollapsed">
      <ul class="navbar-nav ml-auto navbar-center " style="align-items: center;" id="mySidenav">
        <li class="nav-item">
          <a class="nav-link" routerLink="/home" routerLinkActive="active" fragment="home">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/home']" routerLinkActive="active" fragment="services">Services</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/home']" routerLinkActive="active" fragment="about21">About</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/home']" routerLinkActive="active" fragment="features">Features</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/home']" routerLinkActive="active" fragment="clients">Clients</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/home']" routerLinkActive="active" fragment="contact">Contact</a>
        </li>
        <li class="nav-item">
          <button class="btn btn-warning appbutton">Mobile App</button>
        </li>
        <li class="nav-item login">
          <button class="btn btn-warning text-white loginbutton"
            onclick=" window.open('https://client.iceipts.com/#/auth/login','_blank')" taget="_blank">Login</button>
        </li>
      </ul>

    </div>
  </div>
</nav>

<router-outlet></router-outlet>