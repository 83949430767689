<!-- Service Start -->
<section class="section" id="services">
    <div class="container mt-5">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center my-2 mb-5">
                    <h3 class="font-weight-normal text-dark">Our <span class="text-warning">Services</span></h3>
                    <!-- <p class="text-muted">Maecenas tempus tellus eget condimentum rhoncus sem quam semper libero pulvinar hendrerit id lorem.</p> -->
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-blue">
                    <div class="mb-5">
                        <i class="pe-7s-headphones service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Awesome Support</h5>
                    <p class="text-muted service-subtitle mb-4">At our company, we believe in offering awesome support to our customers. We provide a wide range of services and software solutions that are cloud-based and easy to use.</p>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-blue">
                    <div class="mb-5">
                        <i class="pe-7s-tools service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Solutions Business</h5>
                    <p class="text-muted service-subtitle mb-4"> Our team of experienced professionals is dedicated to delivering innovative solutions that are tailored specifically for your business. Our experts can help you anytime.</p>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-blue">
                    <div class="mb-5">
                        <i class="pe-7s-display1 service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Digital Design</h5>
                    <p class="text-muted service-subtitle mb-4">Our team of experienced professionals is equipped with the latest software to create stunning designs that are tailored to our clients' needs. Our experts can help you anytime.</p>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-blue">
                    <div class="mb-5">
                        <i class="pe-7s-cup service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Goal Business</h5>
                    <p class="text-muted service-subtitle mb-4">Our cloud-based services are tailored to the needs of each company, and our team of experts is dedicated to ensuring that every customer gets the most out of our software.</p>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-blue">
                    <div class="mb-5">
                        <i class="pe-7s-light service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Branding Identity</h5>
                    <p class="text-muted service-subtitle mb-4">Our cloud-based solutions ensure that customers have access to all the necessary information and resources they need in order to build an effective brand identity.</p>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-blue">
                    <div class="mb-5">
                        <i class="pe-7s-graph1 service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Dynamic Growth</h5>
                    <p class="text-muted service-subtitle mb-4">Using cloud-based software solutions can also help you save time by streamlining processes, eliminating manual tasks, and providing better insights into customer behavior.</p>
                    
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Service End -->