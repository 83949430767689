<section class="section bg-white" id="about21">
  <!-- MAIN CONTAINER -->
  <!-- container-fluid about us and vision -->
  <div class="container bg-white mt-2">
    <!-- About us Start -->

    <div class="row mb-5 mt-5 bg-light">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row">
            <!-- Content of about us -->
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-6 aboutus">
                  <div class="title text-center mt-3 mb-3">
                    <h3 class="font-weight-normal text-dark about">
                      About <span class="text-warning">Us</span>
                    </h3>
                    <br>
                    <!-- <p class="text-muted text-center">
                       <span class = "top">Empowering you with seamless warranty management</span> 
                      Enjoy unparalleled ease of access and a 360-degree view of your business - all from the comfort of your own home or office with iCeipts.
                      Empowering you with seamless warranty management
                    </p> -->
                    <p class="text-muted text-justify">
                      At iCeipts, we have developed a transformative platform that is set to redefine the way people shop and experience. Our goal is to provide consumers with a hassle-free shopping experience by eliminating the need to wait in queues for products.
                      Additionally, we offer a unique data-driven solution for guaranty and warranty management, connecting customers to service centres seamlessly whenever there is an issue with a product.
                      Our cloud-based SAAS ERP solution streamlines operations and provides real-time analytics and insights to the retailers.
                       iCeipts replaces physical papers with digital records, making it easier for customers to claim the guarantee of their products.
                    </p>
                   </div> 
              </div>
              <!-- Background image -->
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-6">
                <img
                  class="img-responsive fit-cover aboutImg"
                  src="../../../assets/images/hero-7-bg-new.png"
                  alt=""
                  style="width:100%"
                />
              </div>
          </div>
      </div>
  </div>
  <!-- About us End -->

  <br>
  <br>

  <!-- Our Vision starts -->

  <div class="row mb-5 bg-white">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row">
            <!-- Content of vision -->
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-6 vision">
                  <div class="title text-center mb-3">
                    <h3 class="font-weight-normal text-dark text-center about">
                      Our <span class="text-warning">Vision</span>
                    </h3>
                    <br>
                    <p class="text-muted text-justify">
                        To be India's top supplier of digital warranty and guarantee
                        management solutions so that customers can take control of their
                        product information and the claims process. The process of obtaining
                        a warranty or guarantee should be simple for everyone in the future
                        when physical papers are no longer required.</p>
                  </div>
              </div>
              <!-- Background image -->
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-6">
                  <img
                  class="img-responsive founderPic"
                  src="../../../assets/images/founderAndDotted.png"
                  alt=""
                  />
              </div>
          </div>
      </div>
  </div>

    <!-- Our Vision ends -->
    
  <br>
  <br>

    <!-- Why Choose iCeipts starts -->

    <!-- bg full cover  -->
    <!-- Main whole content -->
  <div class="row bg-light">
    <div class="col-lg-12">
      <div class="row">
          <!-- Heading of why choose -->
            <div class="col-lg-12 line-height-1_4">
                <div class="title text-center my-5">
                  <h3 class="font-weight-normal text-dark whyHeading">
                    Why is <span class = "text-warning">iCeipts.</span> the preferred Operating Software for business?
                  </h3>
                </div>
            </div>
            <!-- Left Section -->
            <div class="col-lg-4">
              <div class="row">
                <!-- Left Upper Section -->
                <div class="col-lg-10 col-md-11 col-sm-12">
                  <img 
                  class = "img-fluid my-3 icons"
                  src = "../../../assets/images/goodbyeWarrentyPapers.png">
                  <div class = "360text">
                    <h4>Cloud-Based SAAS ERP Solution</h4>
                    <p class = "text-mute">We understand how difficult it is to run a business, which is why we developed a cloud-based SAAS ERP solution that streamlines your operations and provides real-time analytics and insights.</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <!-- Left Lower Section -->
                <div class="col-lg-10">
                  <img 
                  class = "img-fluid my-3 icons"
                  src = "../../../assets/images/AI-ML.png">
                  <div class = "360text">
                    <h4>AI and ML-Based Automation</h4>
                    <p class = "text-mute">Our AI and ML-powered automation tools handle time-consuming and tedious tasks while allowing you to focus on what really matters: growing your business. With iCeipts, you'll be able to stay ahead of the competition and outperform them.</p>
                  </div>
                </div>              
              </div>
            </div>
            <!-- Middle Section -->
            <div class="col-lg-4" style="display: flex ;justify-content: center !important; align-items: center !important;">
              <img
                class="img-fluid img-fluid1"
                style = "margin-top:30%; margin-right: 20%; width:100% !important;"
                src="../../../assets/images/MobileOriginal.png"
                alt=""
              />
            </div>
            <!-- Right Section -->
            <div class="col-lg-4">
                  <div class="row">
                    <!-- Right Upper Section -->
                    <div class="col-lg-10">
                      <img 
                      class = "img-fluid my-3 icons"
                      src = "../../../assets/images/realTimeAnalysis.png">
                      <div class = "360text">
                        <h4>360-degree Real-Time Analysis</h4>
                        <p class = "text-mute">You'll have unparalleled ease of access and a 360-degree     view of your business with iCeipts, all from the comfort of your own home or office.
                        Our platform save you time and energy.
                        </p>
                      </div>
                    </div>
              </div>
              <div class="row">
                <!-- Right Lower Section -->
                <div class="col-lg-10">
                  <img 
                  class = "img-fluid my-3 icons"
                  src = "../../../assets/images/saygoodby.png">
                  <h4>Say Goodbye To Paper Warranty Cards</h4>
                  <p class = "text-mute">We understand the hassle of keeping track of physical warranty and guarantee papers, that's why we've made it our mission to digitalize the process and make it easier for you to claim the guarantee of your Products and Services.</p>
                </div>       
              </div>
            </div>
        </div>
    </div>
  </div>

    <!-- Our Vision ends -->

</div>
</section>

    <!-- <div class="row aboutus">
      <div class="col-lg-12 col-md-12 col-sm-12">
            <!-- Background Image 
              <img
                class="img-responsive fit-cover"
                src="../../../assets/images/hero-7-bg.png"
                alt=""
              />
              <!-- Content in About us 
              <div class="content">
                <div class="title text-center mb-3">
                  <h3 class="font-weight-normal text-dark about">
                    About <span class="text-warning">Us</span>
                  </h3>
                  <br />
                  <p class="text-muted text-center">
                    Empowering you with seamless warranty management<br />
                    ENJOY UNPARALLELED EASE OF ACCESS AND A 360-DEGREE VIEW OF YOUR BUSINESS - ALL FROM THE COMFORT OF YOUR OWN HOME OR OFFICE WITH ICEIPTS.
                  </p>
                </div>
            </div>
      </div>
  </div> -->




    <!-- HEADING -->
    <!-- <div class="row">
      <div class="col-lg-12">
        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8 col-sm-10">
            <div class="title text-center mb-3">
              <h3 class="font-weight-normal text-dark">
                About <span class="text-warning">Us</span>
              </h3>
              <br />
              <p class="text-muted text-center">
                Empowering you with seamless warranty management<br />
                ENJOY UNPARALLELED EASE OF ACCESS AND A 360-DEGREE VIEW OF YOUR
                BUSINESS - ALL FROM THE COMFORT OF YOUR OWN HOME OR OFFICE WITH
                ICEIPTS.
              </p>
            </div>
          </div>
        </div>
        <hr />

        <!-- UPPER SECTION 

        <div class="row justify-content-center">
          <!-- LEFT SECTION 

          <!-- RIGHT SECTION 
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div>
              <h6 class="text-dark font-weight-normal f-20 mb-3">
                <span class="text-warning">Why</span> is iCeipts. the preferred
                operating software for businesses?
              </h6>
            </div>
            <hr />
            <div>
              <h6 class="text-dark font-weight-normal f-20 mb-3">
                Cloud-based SAAS ERP solution
              </h6>
              <p class="text-muted">
                We understand how difficult it is to run a business, which is
                why we developed a cloud-based SAAS ERP solution that
                streamlines your operations and provides real-time analytics and
                insights.
              </p>
            </div>
            <hr />
            <div>
              <h6 class="text-dark font-weight-normal f-20 mb-3">
                AI and ML-based automation
              </h6>
              <p class="text-muted">
                Our AI and ML-powered automation tools handle time-consuming and
                tedious tasks while allowing you to focus on what really
                matters: growing your business. With iCeipts, you'll be able to
                stay ahead of the competition and outperform them.
              </p>
            </div>
            <hr />
            <div>
              <h6 class="text-dark font-weight-normal f-20 mb-3">
                360-degree real-time analytics
              </h6>
              <p class="text-muted">
                You'll have unparalleled ease of access and a 360-degree view of
                your business with iCeipts, all from the comfort of your own
                home or office.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr /> 
    <!-- <div>
      <h6 class="text-dark font-weight-normal f-20 mb-3">
        Say goodbye to paper warranty cards
      </h6>
      <p class="text-muted">
        We understand the hassle of keeping track of physical warranty and
        guarantee papers, that's why we've made it our mission to digitalize the
        process and make it easier for you to claim the guarantee of your
        products.
      </p>
      <p class="text-muted">
        Our platform connects you directly to the service center, eliminating
        the need for paper-based warranty and guarantee tracking. With just a
        few clicks, you can access all of your product information and warranty
        details.At our E-Recipe Company, we believe in making the warranty and
        guarantee claiming process as seamless as possible. Our platform is
        user-friendly and allows you to easily manage all of your products in
        one place.
      </p>
      <p class="text-muted">
        Not only does our platform save you time, it also saves you from the
        stress of having to keep track of physical papers. With our digital
        solution, you can have peace of mind knowing that your product
        information and warranty details are easily accessible.
      </p>
    </div> -->


<!--    LOWER SECTION -->
<!-- <section>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <div class="service-box service-blue">
          <h6 class="service-title text-dark font-weight-normal pt-4 f-20 mb-4">
            Our Mission
          </h6>
          <p class="text-muted font-weight-light">
            To transform the way customers in India, handle their warranty and
            guarantee information by offering a smooth, digital solution. As we
            work towards a more digital India, our aim is to make the claiming
            process easier to understand and more accessible to everyone.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="service-box service-blue">
          <h6 class="service-title text-dark font-weight-normal pt-4 f-20 mb-4">
            Our Vision
          </h6>
          <p class="text-muted font-weight-light">
            To be India's top supplier of digital warranty and guarantee
            management solutions so that customers can take control of their
            product information and the claims process. The process of obtaining
            a warranty or guarantee should be simple for everyone in the future
            when physical papers are no longer required.
          </p>
        </div>
      </div>
    </div>
  </div>
</section> -->