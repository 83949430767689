<!-- Testimonial Start -->
<section class="section bg-light" id="clients">
  <div class="container">
    <div class="row justify-content-center clientHeading">
      <div class="col-lg-12 col-md-8">
        <div class="title text-center mt-3 mb-5">
          <h3 class="font-weight-normal text-dark">Our <span class="text-warning">Clients</span></h3>
        </div>
      </div>
    </div>
    <div class="row clientsText">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <h3 class="font-weight-normal mt-3 line-height-1_4 text-center">Some Words From Our <span
            class="font-weight-medium text-warning">Happy Clients </span></h3>
        <div class="testi-border my-4 mx-auto"></div>
        <p class="text-muted text-center mt-5">We consider our clients as guests and hence as per the indian tradition,
          our guests deserve best.</p>
      </div>
    </div>
  </div>
</section>
<section class="section bg-light">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <!-- <img
            class="img-fluid client"
            style = "width: 100% !important;"
            src="../../../assets/images/clients.png"
            alt=""
          />    -->
        <div class="row">
          <div class="column col-lg-4 col-md-6 col-sm-12">
            <div class="card">
              <p class="text-muted text-justify mx-3">The platform offers solution for warranty and maintenance
                management, ERP, and GST compliance.</p>
              <ul class="list-unstyled f-18 text-warning my-2 text-center">
                <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
              </ul>
              <h5 class="text-center my-2 text-primary">Radhakrishnan</h5>
              <h5 class="text-center my-2">FiFm</h5>
            </div>
          </div>

          <div class="column col-lg-4 col-md-6 col-sm-12">
            <div class="card">
              <p class="text-muted text-justify mx-3">B2C customers can manage all of their product warranties on the
                platform making things easier.</p>
              <ul class="list-unstyled f-18 text-warning my-2 text-center">
                <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
              </ul>
              <h5 class="text-center my-2 text-primary">Harshad</h5>
              <h5 class="text-center my-2">FIGO</h5>
            </div>
          </div>

          <div class="column col-lg-4 col-md-6 col-sm-12">
            <div class="card">
              <!-- <p class="text-muted text-justify mx-3">Our digital receipt solution is built to make the claiming process more accessible.</p>  -->
              <p class="text-muted text-justify mx-3">The platform offers solution for warranty and maintenance
                management, ERP, and GST compliance..</p>
              <ul class="list-unstyled f-18 text-warning my-2 text-center">
                <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
              </ul>
              <h5 class="text-center my-2 text-primary">Shelva Baya</h5>
              <h5 class="text-center my-2">Tone Me</h5>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content mt-5">
      <!-- <div class="row">
            <div class="column col-lg-4 col-md-6 col-sm-12 my-3">
                <div class="card">
                    <p class="text-muted text-justify mx-3">The platform offers solution for warranty and maintainance management, ERP, and GST compliance.</p>
                    <ul class="list-unstyled f-18 text-warning my-2 text-center">
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    </ul>  
                    <h5 class = "text-center my-2 text-primary">Emily Watson</h5>
                    <h5 class = "text-center my-2">FIFM</h5>
                </div>
            </div>
          
            <div class="column col-lg-4 col-md-6 col-sm-12 my-3">
              <div class="card">
                <p class="text-muted text-justify mx-3">B2C customers can manage all of their product warranties on the platform making things easier.</p>
                <ul class="list-unstyled f-18 text-warning my-2 text-center">
                  <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                  <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                  <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                  <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                </ul>  
                <h5 class = "text-center my-2 text-primary">Emily Watson</h5>
                <h5 class = "text-center my-2">FIGO</h5>
              </div>
            </div>
            
            <div class="column col-lg-4 col-md-12 col-sm-12 my-3">
              <div class="card">
                <p class="text-muted text-justify mx-3">Our digital receipt solution is built to make the claiming process more accessible to everyone.</p> 
                <ul class="list-unstyled f-18 text-warning my-2 text-center">
                  <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                  <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                  <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                  <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                </ul>  
                <h5 class = "text-center my-2 text-primary">Emily Watson</h5>
                <h5 class = "text-center my-2">Tone Me</h5>           
              </div>
            </div>
        </div>             -->
    </div>
  </div>


  <!-- LIst of Happy Clients
    FIFM
    Boda- Rider and Customer
    FIGO
    Tone Me -->

  <!-- <div class="container">
      <!-- <div class="row justify-content-center">
        <div class="col-lg-6 col-md-8">
          <div class="title text-center mb-5">
            <h3 class="font-weight-normal text-dark">Our <span class="text-warning">Clients</span></h3>
            <!-- <p class="text-muted">Maecenas tempus tellus eget condimentum rhoncus sem quam semper libero pulvinar
              hendrerit id lorem.lorem 5</p> 
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <h3 class="font-weight-normal mt-3 line-height-1_4">Some Words From Our <span
              class="font-weight-medium text-warning">Happy Clients </span></h3>
          <div class="testi-border my-4"></div>
          <!-- <p class="text-muted">Itaque earum rerum tenetur a sapiente delectus ut aut reiciendis voluptatibus maiores
            alias consequatur.</p> 
        </div>
        <div class="col-lg-8 testi-carousel">
          <owl-carousel-o [options]="testimonialOptions">
            <ng-template carouselSlide>
              <div class="item">
                <div class="testi-content m-3 position-relative">
                  <div class="testi-box p-4">
                    <ul class="list-unstyled f-15 text-warning mb-2">
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    </ul>
                    <p class="text-muted position-relative mb-0 f-14"><span class="f-20 mr-1">"</span> Sed ut
                      perspiciatis unde omnis at iste natus error a voluptatem accusantium doloremque totam. <span
                        class="f-16">"</span></p>
                  </div>
                  <div class="testi-user mt-4">
                    <div class="media align-items-center">
                      <img src="assets/images/user/img-1.jpg" alt="" class="mr-3 img-fluid d-block rounded-circle">
                      <div class="media-body">
                        <h6 class="mb-0 text-dark f-15">Roberta Guevara</h6>
                        <p class="text-muted mb-0 f-14">Web Developer</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="testi-content m-3 position-relative">
                  <div class="testi-box p-4">
                    <ul class="list-unstyled f-15 text-warning mb-2">
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    </ul>
                    <p class="text-muted position-relative mb-0 f-14"><span class="f-20 mr-1">"</span> Sed ut
                      perspiciatis unde omnis at iste natus error a voluptatem accusantium doloremque totam. <span
                        class="f-16">"</span></p>
                  </div>
                  <div class="testi-user mt-4">
                    <div class="media align-items-center">
                      <img src="assets/images/user/img-1.jpg" alt="" class="mr-3 img-fluid d-block rounded-circle">
                      <div class="media-body">
                        <h6 class="mb-0 text-dark f-15">Roberta Guevara</h6>
                        <p class="text-muted mb-0 f-14">Web Developer</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="testi-content m-3 position-relative">
                  <div class="testi-box p-4">
                    <ul class="list-unstyled f-15 text-warning mb-2">
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    </ul>
                    <p class="text-muted position-relative mb-0 f-14"><span class="f-20 mr-1">"</span> Sed ut
                      perspiciatis unde omnis at iste natus error a voluptatem accusantium doloremque totam. <span
                        class="f-16">"</span></p>
                  </div>
                  <div class="testi-user mt-4">
                    <div class="media align-items-center">
                      <img src="assets/images/user/img-1.jpg" alt="" class="mr-3 img-fluid d-block rounded-circle">
                      <div class="media-body">
                        <h6 class="mb-0 text-dark f-15">Roberta Guevara</h6>
                        <p class="text-muted mb-0 f-14">Web Developer</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="testi-content m-3 position-relative">
                  <div class="testi-box p-4">
                    <ul class="list-unstyled f-15 text-warning mb-2">
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    </ul>
                    <p class="text-muted position-relative mb-0 f-14"><span class="f-20 mr-1">"</span> Sed ut
                      perspiciatis unde omnis at iste natus error a voluptatem accusantium doloremque totam. <span
                        class="f-16">"</span></p>
                  </div>
                  <div class="testi-user mt-4">
                    <div class="media align-items-center">
                      <img src="assets/images/user/img-1.jpg" alt="" class="mr-3 img-fluid d-block rounded-circle">
                      <div class="media-body">
                        <h6 class="mb-0 text-dark f-15">Roberta Guevara</h6>
                        <p class="text-muted mb-0 f-14">Web Developer</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="testi-content m-3 position-relative">
                  <div class="testi-box p-4">
                    <ul class="list-unstyled f-15 text-warning mb-2">
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item mr-1"><i class="mdi mdi-star"></i></li>
                      <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    </ul>
                    <p class="text-muted position-relative mb-0 f-14"><span class="f-20 mr-1">"</span> Sed ut
                      perspiciatis unde omnis at iste natus error a voluptatem accusantium doloremque totam. <span
                        class="f-16">"</span></p>
                  </div>
                  <div class="testi-user mt-4">
                    <div class="media align-items-center">
                      <img src="assets/images/user/img-1.jpg" alt="" class="mr-3 img-fluid d-block rounded-circle">
                      <div class="media-body">
                        <h6 class="mb-0 text-dark f-15">Roberta Guevara</h6>
                        <p class="text-muted mb-0 f-14">Web Developer</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div> 
    </div> -->
</section>
<!-- Testimonial End -->