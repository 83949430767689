<!-- footer Start -->
<section class="footer footertext bg-backf">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="tagline font-weight-normal my-2">
                    <p>
                        The technology that looks impossible today will be a part of our
                        life tomorrow. Iceipts Technology creating difference.
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="ceo float-right ">
                    <p class="font-weight-normal my-2">
                        <span class="ceoName">SIBANEE KAR</span><br />
                        <span class="ceoPosition">CEO and Founder</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="footer bg-backf">

    <div class="container">
        <div class="row ">
            <div class="col-12">
                <div class="content py-5">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-12">
                            <div class="mb-4">
                                <!-- <a href="layout-one"><img src="assets/images/logo-dark.png" alt="" class="logo-dark" height="26" /></a> -->
                                <img src="assets/images/new-logo-iceipt-blue.png" alt="iCeipts" class="logo-dark"
                                    height="60" />
                                <!-- <p class="text-muted mt-4 mb-2">Yourcompanyemailid@gmail.com</p> -->
                                <p class="text-muted mt-4 mb-2">admin@iceipts.com</p>
                                <h6 class="text-muted font-weight-normal">+918308099400 +971561944600</h6>
                            </div>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-6">
                                    <h6 class="footer-list-title text-dark mb-3">Services</h6>
                                    <ul class="list-unstyled company-sub-menu">
                                        <li><a href="">Awesome Support</a></li>
                                        <li><a href="">Solutions Business</a></li>
                                        <li><a href="">Digital Pattern</a></li>
                                        <li><a href="">Goal Business</a></li>
                                        <li><a href="">Branding Identity</a></li>
                                        <li><a href="">Dynamic Growth</a></li>
                                    </ul>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6">
                                    <!-- <h6 class="footer-list-title text-dark mb-3">About Us</h6>
                                            <ul class="list-unstyled company-sub-menu">
                                                <li><a href="">Contact Us</a></li>
                                                <li><a href="">FAQs</a></li>
                                                <li><a href="">Blog</a></li>
                                                <li><a href="">Privacy Policy</a></li>
                                            </ul> -->
                                    <h6 class="footer-list-title text-dark mb-3">Features</h6>
                                    <ul class="list-unstyled company-sub-menu">
                                        <li><a href="">Warranty Management</a></li>
                                        <li><a href="">ERP</a></li>
                                        <li><a href="">GST</a></li>
                                        <li><a href="">Scan And Pay</a></li>
                                        <li><a href="">Employee Management</a></li>
                                        <li><a href="">Self-Checkout</a></li>
                                    </ul>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6">
                                    <h6 class="footer-list-title text-dark mb-3">Our Address</h6>
                                    <p class="text-muted f-14">
                                        602, Parkridge 6th floor, Baner-Pashan Link Rd, Near Kumar
                                        Shantiniketan, Pashan, Pune, Maharashtra 411021
                                    </p>
                                    <!-- <h6 class="text-muted pb-2">Email: Support@gamil.com</h6> -->
                                    <h6 class="text-muted pb-2">Email: admin@iceipts.com</h6>
                                    <ul class="list-unstyled footer-social-list mt-4">
                                        <li class="list-inline-item">
                                            <a href="https://www.facebook.com/people/iCeipts/100063970088207/"
                                                target="_blank">
                                                <i class="mdi mdi-facebook"></i></a>
                                        </li>
                                        <!-- <li class="list-inline-item"><a href="#"><i class="mdi mdi-instagram"></i></a></li> -->
                                        <li class="list-inline-item">
                                            <a href="https://www.linkedin.com/company/iceipts-technology/"
                                                target="_blank">
                                                <i class="mdi mdi-linkedin"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3 mt-5 footertext">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="text-center text-muted">
                                <p class="mb-0 f-15">
                                    {{ currentYear }} © iCeipts Technology.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <img
            class="img-fluid footer"
            style="width: 100% !important; height: auto !important;"
            src="../../../assets/images/footer-Copy.png"
            alt=""
            /> -->
        </div>
    </div>
</section>
<!-- footer End -->