import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whyiceiptwala',
  templateUrl: './whyiceiptwala.component.html',
  styleUrls: ['./whyiceiptwala.component.css']
})
export class WhyiceiptwalaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
