<div appScrollspy>
    <!-- Hero Start -->
    <section class="hero-3-bg position-relative overflow-hidden d-flex align-items-center bg-blue" id="home">
        <div class="hero-3-bg-overlay"></div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="text-center">
                        <!-- <h1 class="hero-3-title text-white mb-4 font-weight-normal line-height-1_4">
                            <div class = "heading"
                            >iCeipts Technology</div><br>
                            <div class = "sub-heading"
                            >Effortlessly Efficient</div></h1> -->
                        <div class="hero-3-title text-white mb-4 font-weight-normal line-height-1_4">
                            <h1 class = "heading">iCeipts Technology</h1>
                            <h2 class = "sub-heading">Effortlessly efficient</h2>
                        </div>    
                        <p class="text-white-50 mb-4">" A digital platform that makes your life easy and the efficient way to manage all your business under one roof. A company that values your time and privacy ."</p>
                        <!-- <div class = "desktopapp">
                            <button class="btn btn-warning desktopappbutton" routerLink="/public/feature">Desktop App</button>
                        </div> -->
                        <!-- <a href="" class="btn btn-danger download">Download</a> -->
                    </div>
                </div>
            </div>
    
            <div class="row justify-content-center">
                <div class="col-md-10">
                    <div class="hero-3-img">
                        <img 
                            src="assets/images/FrameHome.png" 
                            alt="" 
                            class="img-fluid mx-auto d-block">
                        <!-- <img src="assets/images/home-video.mp4" alt="" class="img-fluid mx-auto d-block"> -->
                        <!-- <video width="320" height="240" controls>
                            <source src="assets/images/home-video.mp4" type="video/mp4">
                            <source src="movie.ogg" type="video/ogg">
                            Your browser does not support the video tag.
                        </video> -->
                    </div>
                </div>
            </div>
        </div>
    </section>

  
</div>

<app-whyiceiptwala></app-whyiceiptwala>  
<app-servicewala></app-servicewala>
<app-aboutwala></app-aboutwala>
<app-featurewala></app-featurewala>
<app-clientwala></app-clientwala>
<app-contactwala></app-contactwala>
<app-footerwala></app-footerwala>