<!-- About us Start -->
<section class="section bg-light">
    <!-- MAIN CONTAINER -->
    <div class="container">
        <!-- HEADING -->
        <div class="row">
            <div class="col-lg-12">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-md-10 col-sm-10">
                        <div class="title text-center mb-3">
                            <h3 class="font-weight-normal text-dark whychoose">Why Choose <span
                                    class="text-warning">iCeipts?</span></h3>
                            <br>
                            <p class="text-muted text-justify headingText">We know how difficult it is to run a
                                business, which is why our team of experienced professionals is dedicated to deliver
                                innovative solutions that are tailored specifically for your business needs. Whether you
                                need help with digital design, branding identity, or cloud-based services, we can
                                provide you with the expertise and software necessary to help your business thrive.
                                We Empower retailers with AI-enabled solutions for seamless shopping, personalized
                                experiences, and efficient post-sale guaranty and warranty management.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>




    </div>

    <div class="container mt-5">
        <!-- [ngStyle]="{'background' :'url(assets/images/whyiceipts.png)'}" -->
        <!-- <img cImg fluid src="assets/images/whyiceipts.png" /> -->
        <!-- <img src="assets/images/whyiceipts.png" alt="" class="img-fluid mx-auto d-block">        -->
        <div class="row">
            <div class="column col-lg-4 col-md-6 col-sm-12 my-3">
                <div class="card">
                    <img class="img-fluid my-3 b2b" src="../../../assets/images/B2B.png">
                    <h5>B2B customer</h5>
                    <div class="testi-border mt-1 mb-3 symbol"></div>
                    <p class="text-muted text-justify">
                        Our platform analyses customer behavior, preferences, and purchase patterns, empowering
                        businesses to make informed decisions, improve product offerings, and enhance overall customer
                        satisfaction by using our Cloud-Based ERP.
                        B2B customer can benefit from iCeipts by streamlining their operations and gaining insights into
                        their business processes. For example, a manufacturer can use iCeipts to manage all of their
                        product warranties.</p>
                </div>
            </div>

            <div class="column col-lg-4 col-md-6 col-sm-12 my-3">
                <div class="card">
                    <img class="img-fluid my-3 b2c" src="../../../assets/images/B2C (1).png">
                    <h5>B2C customer</h5>
                    <div class="testi-border mb-3 symbol"></div>

                    <p class="text-muted text-justify">
                        With our advanced AI capabilities, we enable customers to effortlessly shop without wasting time
                        standing in queues.
                        We also go beyond the traditional boundaries of retail by placing a strong emphasis on
                        post-purchase experience.
                        Our platform provides customers with a comprehensive and effortless guaranty and warranty
                        management process. We proactively connect customers to authorized service centres,
                        ensuring prompt resolution of any product issues and enhancing overall product satisfaction.
                    </p>

                </div>
            </div>

            <div class="column col-lg-4 col-md-6 col-sm-12 my-3">
                <div class="card">
                    <img class="img-fluid my-3 digitalReceipt" src="../../../assets/images/DigitalReceipt (1).png">
                    <h5>Digital Receipt</h5>
                    <div class="testi-border mt-1 mb-3 symbol"></div>
                    <p class="text-muted text-justify">Our digital receipt solution is built to make the claiming
                        process more accessible to everyone. Say goodbye to paper warranty and guarantee cards, and say
                        hello to a digital platform that connects you directly to the service center.
                        By personalizing the shopping experience, we empower users to quickly and easily locate their
                        desired items, enhancing convenience and satisfaction.
                    </p>

                </div>
            </div>
        </div>

    </div>


</section>



<!-- About us End -->
<!--  -->